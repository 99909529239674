<template>
    <div>
        <v-container>
        <div class="hidden-sm-and-down"> <!--full screen --> 
        <div class="container">
                <div class="centro">
                    <div v-if="verify==0">
                        <v-row>
                            <v-col cols="12" md="12" class="text-center">
                                <!-- <v-icon x-large color="red">cancel</v-icon> -->
                                <h4>Pago seguro con:</h4>
                                <div style="width:100%" class="text-center">
                                    <img src="@/assets/logo-web-pay-plus.png"/>
                                </div>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <strong>Estamos validando tu solicitud</strong>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <v-progress-linear class="mt-5" indeterminate color="purple"></v-progress-linear>
                                <!-- <v-btn @click="home()" color="purple" large block dark rounded>Volver al comercio</v-btn> -->
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="verify==1">
                        <v-row>
                            <v-col cols="12" md="12" class="text-center">
                                <v-avatar size="100" color="purple">
                                    <v-img src="@/assets/perro_ok.png" alt=""></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <h1 class="purple--text">Comprobante de pago</h1>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <h2 class="purple--text">¡Hola!</h2>
                                <h4 class="purple--text">Tu pago a traves de nuestro sitio web fue exitoso</h4>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center" style="background:purple">
                                <h4 class="white--text">Detalle de compra:</h4>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-row>
                                    <v-col cols="8" md="8"><strong>Producto</strong></v-col>
                                    <v-col cols="2" md="2" class="text-center"><strong>Cantidad</strong></v-col>
                                    <v-col cols="2" md="2" class="text-center"><strong>Precio</strong></v-col>
                                </v-row>                            
                                <v-row class="mt-n5" v-for="(item,i) in detalle" :key="i">
                                    <v-col cols="8" md="8">{{item.producto}}</v-col>
                                    <v-col cols="2" md="2" class="text-center">{{new Intl.NumberFormat("de-DE").format(parseFloat(item.cantidad))}}</v-col>
                                    <v-col cols="2" md="2" class="text-center">${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</v-col>
                                </v-row>                            
                            </v-col>
                            <v-col cols="12" md="12" class="text-center" style="background:purple">
                                <h4 class="white--text">Detalle del pago:</h4>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-row class="mt-2">
                                    <v-col class="mt-n5" cols="6" md="6">N° de orden</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">{{orden}}</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">N° de Tarjeta</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">{{tarjeta}}</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">Fecha operación</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">{{fecha}}</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">Hora operación</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">{{hora}}</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">Monto pagado</v-col>
                                    <v-col class="mt-n5" cols="6" md="6">${{new Intl.NumberFormat("de-DE").format(parseFloat(monto))}}</v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <v-btn @click="home()" color="purple" dark rounded>Volver al comercio</v-btn>
                            </v-col>
                        </v-row>
                </div>
                    <div v-if="verify==2">
                        <v-row>
                            <v-col cols="12" md="12" class="text-center">
                                <v-avatar size="100" color="purple">
                                    <v-img src="@/assets/perro_error.png" alt=""></v-img>
                                </v-avatar>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <h2 class="purple--text">¡Hola!</h2>
                                <h4 class="purple--text">Ha ocurrido un problema</h4>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center" style="background:purple">
                                <h4 class="white--text">Este es el Detalle:</h4>
                            </v-col>
                            <v-col cols="12" md="12">
                                <h4>{{respuesta}}</h4>
                            </v-col>
                            <v-col cols="12" md="12" class="text-center">
                                <v-btn @click="home()" color="purple" dark rounded>Volver al comercio</v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </div>   
        </div>
        <div class="hidden-sm-and-up"> <!--mobile screen-->
            <div v-if="verify==0">
                <v-row>
                    <v-col cols="12" md="12" class="text-center">
                        <!-- <v-icon x-large color="red">cancel</v-icon> -->
                        <h4>Pago seguro con:</h4>
                        <div style="width:100%" class="text-center">
                            <img src="@/assets/logo-web-pay-plus.png"/>
                        </div>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <strong>Estamos validando tu solicitud</strong>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <v-progress-linear class="mt-5" indeterminate color="purple"></v-progress-linear>
                        <!-- <v-btn @click="home()" color="purple" large block dark rounded>Volver al comercio</v-btn> -->
                    </v-col>
                </v-row>
            </div>
            <div v-if="verify==1">
                <v-row>
                    <v-col cols="12" md="12" class="text-center">
                        <v-avatar size="100" color="purple">
                            <v-img src="@/assets/perro_ok.png" alt=""></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <h1 class="purple--text">Comprobante de pago</h1>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <h2 class="purple--text">¡Hola!</h2>
                        <h4 class="purple--text">Tu pago a traves de nuestro sitio web fue exitoso</h4>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center" style="background:purple">
                        <h4 class="white--text">Detalle de compra:</h4>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-row>
                            <v-col cols="6" md="6"><strong>Producto</strong></v-col>
                            <v-col cols="3" md="3" class="text-center"><strong>Cantidad</strong></v-col>
                            <v-col cols="3" md="3" class="text-center"><strong>Precio</strong></v-col>
                        </v-row>                            
                        <v-row class="mt-n5" v-for="(item,i) in detalle" :key="i">
                            <v-col cols="6" md="6">{{item.producto}}</v-col>
                            <v-col cols="3" md="3" class="text-center">{{new Intl.NumberFormat("de-DE").format(parseFloat(item.cantidad))}}</v-col>
                            <v-col cols="3" md="3" class="text-center">${{new Intl.NumberFormat("de-DE").format(parseFloat(item.precio))}}</v-col>
                        </v-row>                            
                    </v-col>
                    <v-col cols="12" md="12" class="text-center" style="background:purple">
                        <h4 class="white--text">Detalle del pago:</h4>
                    </v-col>
                    <v-col cols="12" md="12">
                        <v-row class="mt-2">
                            <v-col class="mt-n5" cols="6" md="6">N° de orden</v-col>
                            <v-col class="mt-n5" cols="6" md="6">{{orden}}</v-col>
                            <v-col class="mt-n5" cols="6" md="6">N° de Tarjeta</v-col>
                            <v-col class="mt-n5" cols="6" md="6">{{tarjeta}}</v-col>
                            <v-col class="mt-n5" cols="6" md="6">Fecha operación</v-col>
                            <v-col class="mt-n5" cols="6" md="6">{{fecha}}</v-col>
                            <v-col class="mt-n5" cols="6" md="6">Hora operación</v-col>
                            <v-col class="mt-n5" cols="6" md="6">{{hora}}</v-col>
                            <v-col class="mt-n5" cols="6" md="6">Monto pagado</v-col>
                            <v-col class="mt-n5" cols="6" md="6">${{new Intl.NumberFormat("de-DE").format(parseFloat(monto))}}</v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <v-btn @click="home()" color="purple" dark rounded>Volver al comercio</v-btn>
                    </v-col>
                </v-row>
            </div>
            <div v-if="verify==2">
                <v-row>
                    <v-col cols="12" md="12" class="text-center">
                        <v-avatar size="100" color="purple">
                            <v-img src="@/assets/perro_error.png" alt=""></v-img>
                        </v-avatar>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <h2 class="purple--text">¡Hola!</h2>
                        <h4 class="purple--text">Ha ocurrido un problema</h4>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center" style="background:purple">
                        <h4 class="white--text">Este es el Detalle:</h4>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <h4 class="mt-5 mb-5">{{respuesta}}</h4>
                    </v-col>
                    <v-col cols="12" md="12" class="text-center">
                        <v-btn @click="home()" color="purple" dark rounded>Volver al comercio</v-btn>
                    </v-col>
                </v-row>
            </div>
        </div>
        </v-container>
    </div>
</template>
<style scoped>
.contenedor {
  display: flex;
  justify-content: center;
  align-items: center;
}
.centro{
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
<script>
import axios from 'axios';
export default {
    name:'Pagos',
    data: () => ({
        contrato:'INV-1010-30676',
        token:'',
        tkn:'',
        verify:1,  // 0 validando; 1 exito; 2 error
        //codigos NIVEL 2 revisar apartado transbank developer codigos de error
        //esta activacion de nivel 2 debe ser realizado por el cliente de transbank
        response_codes:[
            //{ id: 0, txt:'Transacción exitosa'},
            { id:-1, txt:'Tarjeta inválida'},
            { id:-2, txt:'Error de conexión'},
            { id:-3, txt:'Excede monto máximo'},
            { id:-4, txt:'Fecha de expiración inválida'},
            { id:-5, txt:'Problema en autenticación'},
            { id:-6, txt:'Rechazo general'},
            { id:-7, txt:'Tarjeta bloqueada'},
            { id:-8, txt:'Tarjeta vencida'},
            { id:-9, txt:'Transacción no soportada'},
            { id:-10, txt:'Problema en la transacción'},
            { id:-11, txt:'Excede límite de reintentos de rechazos'},
        ],
        codigo:null,
        respuesta:null,
        // datos de la orden y transaccion
        orden:'',
        tarjeta:'',
        fecha:'',
        hora:'',
        monto:'',
        detalle:[],
    }),
    mounted(){
        this.tkn = this.$route.params.tk;
        this.token=localStorage.getItem('tokenCompra');
        //console.log(this.tkn,this.token);
        if (this.tkn=this.token){
            if (this.token!='' && this.token!=null){
                this.verify=0
                this.verificarPago()
            } 
        }
        // else {
        //     this.respuesta='Solicitud no encontrada'
        //     this.verify=2;
        // }
    },
    methods:{
        home(){
            this.$router.push({name:'Home'});
        },
        async verificarPago(){
            try {
                const params={ 
                    'token': this.token,
                    'contrato': this.contrato
                };
                const response = await axios.post('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/transbank/webpayplus/verify/',params);
                const datos=response.data;
                console.log(datos);
                // this.storeLocal=localStorage.removeItem('transbank');
                // this.storeLocal=localStorage.setItem('transbank',JSON.stringify(datos));
                if(datos.response_code<=0){
                    //datos de la tranzasccion
                    const oc=datos.buy_order;
                    this.fecha=this.format_fecha(datos.transaction_date);
                    this.hora=this.format_hora(datos.transaction_date);
                    this.tarjeta=datos.card_detail.card_number;
                    this.monto=datos.amount;
                    this.orden=oc.substring(3,oc.length);
                    //analizamos el codigo de respuesta
                    this.codigo=datos.response_code;
                    if(this.codigo!=0){
                        const found = this.response_codes.filter(res => parseInt(res.id)==parseInt(this.codigo));
                        console.log(found);
                        if(found.length!=0){
                            console.log('condigo de error: '+ this.codigo);
                            this.respuesta=found[0].txt;
                            this.verify=2
                        } else {
                            console.log('condigo de error: '+ this.codigo);
                            this.respuesta='Inconsistencia en la transacción'
                            this.verify=2
                        }
                    } else {
                        console.log('condigo exitoso: '+ this.codigo);
                        //datos del pedido
                        const response1 = await axios.get('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/leer/ordenWeb/detalle/'+this.contrato+'/'+this.orden);
                        const datos1=response1.data;
                        //console.log(datos1);
                        if(datos1[0].existe=='si'){
                            datos1.forEach(element => {
                                this.detalle.push({
                                    'producto':element.Producto,
                                    'cantidad':element.Cantidad,
                                    'precio':element.Precio
                                })
                            });
                        }
                        this.respuesta='Transacción exitosa'
                        this.verify=1
                    }
                    //grabar codigo de respuesta transbank
                    let saveResponse='Codigo Nulo';
                    const par={
                        'response':this.codigo,
                        'orden': this.orden,
                        'monto': parseFloat(this.monto)
                    };
                    const res = await axios.put('https://www.app.bodeganet.cl/ApiPHP_ClientesPWA/public/api/inv/transbank/webpayplus/response/save/'+this.contrato,par);
                    const dat = res.data;
                    if(dat=='si'){ 
                        saveResponse='Codigo Ok';
                        await this.sincronizaStock();
                    };
                    console.log(saveResponse);
                } else {
                    //error de proceso de pago no terminado
                    this.respuesta='Solicitud sin respuesta'
                    this.verify=2;
                };
                this.token='';
                localStorage.removeItem('tokenCompra');
            } catch (error) {
                console.log(error)
            }
        },
        format_fecha(date){
            var d = new Date(date);
            date = [
                ('0' + d.getDate()).slice(-2),
                ('0' + (d.getMonth() + 1)).slice(-2),
                d.getFullYear()
            ].join('-');
            return date;
        },
        format_hora(hour){
            var d = new Date(hour);
            hour = [
                ('0' + d.getHours()).slice(-2),
                ('0' + d.getMinutes()).slice(-2),
                ('0' + d.getSeconds()).slice(-2)
            ].join(':');
            return hour;
        },
        async sincronizaStock(){
            let params;
            let headers;
            try {
                params = {
	                "contrato":this.contrato,
	                "user":"SITIO"
                };
                headers = {
                    'Content-Type': 'application/json',
                };
                const response = await axios.post('http://www.app.bodeganet.cl/ApiEcommerce/public/api/v1/create/token/',params,{headers});
                const dato = response.data;
                console.log(dato.Token);
                if(dato.Token!='error'){
                    params = {
                        "contrato":this.contrato,
                        "portal":99,
                        "user":"SITIO"
                    }
                    headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + dato.Token
                    };
                    const result = await axios.post('http://www.app.bodeganet.cl/ApiEcommerce/public/api/v1/WEB/orders/',params,{headers});
                    const datos = result.data;
                    console.log(datos.message)
                }
            } catch (error) {
               console.log(error) 
            }
        }
    }
}
</script>